@import '../../../variables/color-palettes';

bb-account-statement-business-view {
  bb-dropdown-single-select-ui {
    width: 350px;
  }

  .bb-dropdown__select {
    color: $color-brown-700;
    height: 3rem;
  }

  bb-account-statement-table-container {
    .bb-block {
      margin-top: 1rem;
    }

    .table th[data-role='account-statement-table__date-header'] {
      .th-content>span::before {
        content: '' !important;
      }

    }

    th {
      width: 100px;

      &:nth-child(2) {
        width: auto;
      }
    }
  }
}
bb-custom-account-statement-wrapper bb-tab-group-ui {
  .nav-tabs .nav-link {
    color: $color-brown-900;
  }
}

bb-account-statement-base-filters .dropdown-toggle {
  height: 3rem;
}

bb-custom-account-statement-wrapper .btn-on-color .btn-link-text, .btn-on-color .btn-link-text-light {
  color: $color-red-500 !important;
}