.btn:not(.btn-circle).btn-md {
  min-height: $btn-md-size;
}

.btn.btn-circle.btn-md {
  border-radius: 50%;
  &:focus::before{
    border-radius:50% !important;
  }
}

.btn.btn-circle.btn-link:disabled {
  background-color: transparent;
  border: none;
}

.btn {
  &:not(.btn-unstyled) {
    &:focus::before {
      border-radius: 1.2rem !important;
    }
  }
}

.btn-group {
  .btn:first-child:focus::before {
    border-radius: 1.2rem 0 0 1.2rem !important;
  }

  .btn:last-child:focus::before {
    border-radius: 0 1.2rem 1.2rem 0 !important;
  }
}

.input-group {
  .btn:first-child {
    &:not(.btn-unstyled) {
      &:focus::before {
        border-radius: 1rem 0 0 1rem !important;
      }
    }
  }
  .btn:last-child {
    &:not(.btn-unstyled) {
      &:focus::before {
        border-radius: 0 1.2rem 1.2rem 0 !important;
      }
    }
  }
  .ngb-dp-header {
    .btn {
      &:not(.btn-unstyled) {
        &:focus::before {
          border-radius: 50% !important;
        }
      }
    }
  }
}

.bb-button-bar--reverse.pt-5 {
  padding-top: 0.5rem !important;
}

.bb-button-bar--reverse.mt-3 {
  margin-top: 0 !important;
}

.custom-long-button .btn-md {
  width: 12rem;
}