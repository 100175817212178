@import '../../themes/variables/_chart-color';
.bb-icon {
    &__background {
        &--cyan {
            color: $chart-color-6!important;
            background: $chart-color-6-lightest;
        }

        &--perple {
            color: $chart-color-5!important;
            background: $chart-color-5-lightest;
        }
    }
}