$page-layout-padding-horizontal: 0 1rem;
$navigation-width: 17rem;
$navigation-collapsed-width: 4.5rem;
$layout-max-width: 1584px;
$layout-sider-width: 272px;

.bb-layout__topbar-content-area {
  .bb-progress-tracker {
    left: 62%;
  }
}

.bb-layout__nav-expand-marker {
  align-self: center;
}

.bb-layout__branding-area {
  margin-left: 0.5rem;
}

.bb-page-layout__container {
  padding: 0 !important;
}

.bb-layout__sidebar .bb-layout__vertical-nav-item-link {
  color: $color-brown-900;

  &:focus-visible {
    outline-color: $color-primary;
  }
}

.bb-layout__main .bb-page-layout__content {
  padding: 0 2rem 0 1rem;
  max-width: $layout-max-width;
}

.bb-layout .bb-layout__topbar {
  max-width: calc($layout-max-width + $layout-sider-width);
}
