.bb-accounts-account-cards__card {
  .bb-icon--sm.bb-icon {
    &__background {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1.5rem;
    }
  }
}

.bb-card:not(.bb-card--ignore).bb-card--md.bb-card > .bb-card__body {
  .card-view {
    bottom: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .bb-card:not(.bb-card--ignore).bb-card--md.bb-card > .bb-card__body {
      .card-view {
        bottom: 1rem;
      }
  }
}

bb-accounts-card-additional-details {
  order: 2;
  flex-direction: column-reverse;
}