$color-primary: $color-red-500;

$color-secondary: $color-brown-400;

$color-accent: $color-yellow-500;

$color-success: #25a15f;

$color-danger: $color-red-500;

$color-warning: $color-yellow-500;

$color-warning-lightest: #fdf4e1;

$color-warning-darkest: #85631e;

$color-info: $color-brown-400;

$color-neutral-white: #ffffff;

$color-neutral-white-50: #ffffff80;

$color-neutral-lightest: $color-greyscale-100;

$color-neutral-lighter: $color-greyscale-200;

$color-neutral-light: $color-greyscale-300;

$color-neutral-grey: $color-greyscale-400;

$color-neutral-greyer: $color-greyscale-500;

$color-neutral-greyest: $color-greyscale-600;

$color-neutral-dark: $color-greyscale-700;

$color-neutral-darker: $color-greyscale-800;

$color-neutral-darkest: $color-greyscale-900;

$color-neutral-black: #000000;

$color-typography-black: #2E3637;