// this is generated from premitive color json that shared by designer
$color-greyscale-100: #f5f6f7;

$color-greyscale-200: #d8dbe0;

$color-greyscale-300: #c1c5cc;

$color-greyscale-400: #939ba4;

$color-greyscale-500: #727d83;

$color-greyscale-600: #566269;

$color-greyscale-700: #3d4b53;

$color-greyscale-800: #26353e;

$color-greyscale-900: #0d1e28;

$color-red-100: #f8e1e5;

$color-red-200: #f1c3cb;

$color-red-300: #e48896;

$color-red-400: #d64c62;

$color-red-500: #c8102e;

$color-red-600: #960c23;

$color-red-700: #7d0a1d;

$color-brown-100: #f2f2f2;

$color-brown-200: #e3e2de;

$color-brown-300: #c9c6bf;

$color-brown-400: #aba499;

$color-brown-500: #92897d;

$color-brown-600: #796e65;

$color-brown-700: #5b524d;

$color-brown-800: #4a433f;

$color-brown-900: #37322e;

$color-yellow-100: #fdf6e6;

$color-yellow-200: #fbeccc;

$color-yellow-300: #f8da9a;

$color-yellow-400: #f4c767;

$color-yellow-500: #f1b434;

$color-yellow-600: #a67d27;

$color-yellow-700: #5a4314;