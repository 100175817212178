// @font-face {
//   font-family: 'Roboto-Black';
//   font-style: normal;
//   font-weight: 900;
//   src: url('../fonts/Roboto-Black.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Roboto-Bold';
//   font-style: normal;
//   font-weight: 700;
//   src: url('../fonts/Roboto-Bold.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Roboto-Medium';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../fonts/Roboto-Medium.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Roboto-Regular';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../fonts/Roboto-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Roboto-Light';
//   font-style: normal;
//   font-weight: 300;
//   src: url('../fonts/Roboto-Light.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Roboto-Thin';
//   font-style: normal;
//   font-weight: 100;
//   src: url('../fonts/Roboto-Thin.ttf') format('truetype');
// }


/* Thin */
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto-Thin.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
}

/* Light */
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}

/* Normal */
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto-Regular.ttf) format('truetype');
  font-style: normal;    
  font-weight: 400;
}

/* Medium */
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}

/* Bold */
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto-Bold.ttf) format('truetype');
  font-style: normal;    
  font-weight: 700;
}

/* Black */
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto-Black.ttf) format('truetype');
  font-style: normal;
  font-weight: 900;
}