bb-account-details {
  .bb-tab-container__tabs {
    margin-block-end: 1rem;
  }

  bb-account-info {
    .bb-block--sm,
    .bb-block--md {
      margin-block-end: 0;
    }
  }

  bb-account-info-container {
    .bb-list {
      color: $color-brown-900;
    }
  }
}

bb-account-details .btn-link-text-light {
  color: $color-red-500 !important;
}