$font-family: "Roboto";

$font-family-heading: "Roboto";

$font-size-base: 16;

$font-size-hero: 2.5;

$font-size-h1: 2.125rem;

$font-size-h2: 1.5rem;

$font-size-h3: 1.25rem;

$font-size-highlight: 1.125rem;

$font-size-subtitle: 0.875rem;

$font-size-subheader: 0.75rem;

$font-size-table-default: 0.875rem;

$font-size-table-header: 0.75rem;

$font-weight-regular: 400;

$font-weight-heading: 700;

$line-height-base: 1.5;

$line-height-heading: 1.5rem;

$transaction-table-font-size: 12px;
