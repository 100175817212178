// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  padding: 1.5rem 2rem !important;
}

// make back button as secondary instead of link due to consistency on each modal
.modal-footer>.bb-button-bar button[data-role='confirm-action-cancel-btn'].btn-link {
  border-color: var(--bs-primary);
  &:hover{
    background-color: var(--bs-btn-hover-color);
    color: var(--bs-white);
  }
}

.error {
  color: $color-red-500;
}