.alert-warning {
    background-color: $color-warning-lightest !important;
    color: $color-warning-darkest !important;
    margin-bottom: 2rem !important;

    .bb-icon {
        color: $color-warning !important;
    }
}

.alert-heading {
    font-size: 1rem;
}