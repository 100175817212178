bb-accounts-list-header {
  div:has(bb-search-box-ui) {
    flex-grow: 1;
  }
}

div[data-role='account-action-make-a-payment'] {
  /* hides 'make new payment' action button from account overview screen */
  display: none;
}

a.dropdown-item.dropdown-item--pointer[data-role='navigate-to-payments'] {
  /* hides 'new payment' option button of more menu dropdown from account overview screen */
  display: none;
}

a.dropdown-item.dropdown-item--pointer[data-role='navigate-to-balance-history'] {
  /* hides 'see balance history' option button of more menu dropdown from account overview screen */
  display: none;
}

a.dropdown-item.dropdown-item--pointer[data-role='action-rename-account'] {
  /* hides 'rename' option button of more menu dropdown from account overview screen */
  display: none;
}

a[id^='accounts-transactions-journeys-element'][id$='tab1'][href*='/accounts/details'][href$='balance'] {
  /* hides 'balance history' tab from account details screen */
  display: none;
}

a.dropdown-item.dropdown-item--pointer.bb-accounts-overview-header__sort-dropdown-item[aria-label='Sort by Booked Balance'] {
  /* hides 'balance' option of more sort dropdown from account overview screen */
  display: none;
}

bb-accounts-filter > .bb-accounts-filter-container {
  background-color: $color-brown-100;
}
